import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import FeatureCardsSection from '../components/featureCardsSection';
import BazaKlientow from '../../assets/svg/baza-klientow.svg';
import WynikiSprzedazy from '../../assets/svg/wyniki-sprzedazy.svg';
import Dokumenty from '../../assets/svg/dokumenty.svg';
import OdooLogo from '../../assets/svg/odoo.svg';
import NextcloudLogo from '../../assets/svg/nextcloud.svg';
import SEO from '../components/seo';

const ERPPage = () => {
  const data = useStaticQuery(graphql`
  query ERPImages {
    erpsystemy: file(
        relativePath: {
            eq: "systemy-erp.png"
        }
    ) {
        id
        childImageSharp {
            fluid(quality: 95, traceSVG: { threshold: 22 }) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    kompleksowaerp: file(relativePath: { eq: "kompleksowa-usluga.png" }) {
        id
        childImageSharp {
            fluid(quality: 95, traceSVG: { threshold: 22 }) {
                ...GatsbyImageSharpFluid
            }
        }
    }
}`);
  return (
    <SubLayout
      title={(
        <>
          Systemy
          {' '}
          <span>CRM/ERP</span>
        </>
              )}
      subtitle="Kompleksowe wdrożenia"
      img="/img/bg-systemy-erp.jpg"
    >
      <SEO title="Systemy CRM i ERP - iqcode Software House" description="Wdrażamy nowoczesne systemy CRM i ERP nastawione na automatyzację procesów biznesowych. Świadczymy usługi wdrożeniowe oprogramowania Odoo." />
      <FeatureSectionLight
        title={(
          <>
            Profesjonalne systemy
            {' '}
            <span>CRM i ERP</span>
          </>
                  )}
        img={(
          <Img
            alt="nowoczesne systemy CRM/ERP"
            fluid={data.erpsystemy.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              Systemy CRM to oprogramowanie
              ułatwiające kontakty z klientami
              przedsiębiorstwa. Aplikacje o
              bardziej rozbudowanej
              funkcjonalności to oprogramowanie
              ERP. Pozwalają one na automatyzację
              procesów biznesowych oraz ułatwiają
              śledzenie i koordynację pracy
              różnych działów firmy. Ponadto
              dostarczają cenne dane umożliwiające
              analizę skuteczności przyjmowanych
              strategii.
            </p>
            <p>
              Świadczymy kompleksowe usługi
              wdrażania systemów CRM/ERP.
              Przeprowadzamy wnikliwą analizę
              potrzeb klienta oraz poszukujemy
              potencjalne usprawnienia procesów
              bieznesowych wewnątrz
              przedsiębiorstwa. Oferujemy
              szkolenia na gotowym systemie oraz
              wsparcie techniczne.
            </p>
          </>
                  )}
      />

      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Chcesz wycenić wdrożenie
            {' '}
            <span>CRM/ERP</span>
            ?
          </h3>
          <Link className="btn" to="/kontakt/">Napisz do nas!</Link>
        </div>
      </div>
      <FeatureCardsSection
        title={(
          <>
            Wybrana
            {' '}
            <span>funkcjonalność</span>
          </>
                  )}
        subtitle="Oferowana przez systemy CRM/ERP"
        cards={[
          {
            title: (
              <>
                Kontakty
                <br />
                z Klientami
              </>
            ),
            icon: <BazaKlientow />,
            desc:
                            'Newslettery, powiadomienia SMS i spersonalizowane promocje pozwalają na podtrzymanie kontaktu z dotychczasowymi klientami przy minimalnej czasochłonności.',
          },
          {
            title: (
              <>
                Śledzenie
                <br />
                Wyników
              </>
            ),
            icon: <WynikiSprzedazy />,
            desc:
  <>
    Zaawansowana i spersonalizowana funkcjonalność raportowania umożliwia badanie skuteczności przyjmowanych strategii zgodnie z podejściem
    {' '}
    <i>Data-Driven Decision Making</i>
  </>,
          },
          {
            title: (
              <>
                Automatyzacja
                <br />
                Formalności
              </>
            ),
            icon: <Dokumenty />,
            desc:
                            'Zautomatyzowane generowanie i wysyłanie dokumentów pozwala na zwiększenie wydajności procesu sprzedaży dzięki minimalizacji prostych i repetytywnych czynności.',
          },
        ]}
      />

      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Poznaj
            {' '}
            <span>oprogramowanie</span>
            , które
            wdrażamy
          </h3>
          <div className="container">
            <div className="row">
              <div className="col s12 m12 l6 xl6">
                <OdooLogo style={{ width: '180px', height: 'auto' }} />
                <p className="promo-sub">
                  Nowoczesny i innowacyjny ERP
                </p>
                <Link
                  className="btn"
                  to="/wdrozenia-odoo/"
                >
                  Poznaj Odoo
                </Link>
              </div>

              <div className="col s12 m12 l6 xl6">
                <NextcloudLogo style={{ width: '137px', height: 'auto' }} />
                <p className="promo-sub">
                  Wygodna platforma wymiany plików
                </p>
                <Link
                  className="btn"
                  to="/wdrozenia-nextcloud/"
                >
                  Poznaj Nextcloud
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Kompleksowa </span>
            Usługa
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="Kompleksowe wdrożenia CRM/ERP"
              fluid={data.kompleksowaerp.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Przeprowadzamy integracje systemów CRM i ERP z innymi produktami cyfrowymi
              takimi jak
              {' '}
              <Link to="/strony-internetowe/">strony internetowe</Link>
              ,
              {' '}
              <Link to="/aplikacje-mobilne/">aplikacje mobilne</Link>
              {' '}
              czy aplikacje progresywne (
              <Link to="/aplikacje-progresywne-pwa/">PWA</Link>
              ). Ponieważ dysponujemy szerokim wachlarzem technologii programistycznych, możemy
              realizować złożone projekty łączące wiele dziedzin IT.
            </p>
            <p>
              Oferujemy
              kompleksową realizację projektów informatycznych
              począwszy od projektowania oprogramowania i
              inżynierii wymagań, poprzez prace programistyczne i
              doradztwo projektowe związane z debiutem rynkowym
              aplikacji, a zakończywszy na wsparciu technicznym,
              analityce i ciągłym rozwoju produktu cyfrowego.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <AllServices />
    </SubLayout>
  );
};

export default ERPPage;
